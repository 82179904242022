import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PatientDetails from "./components/PatientDetails";
import PatientList from "./components/PatientList";

function App() {
  return (
      <Routes>
        <Route path="/" element={<PatientList />} />
        
        <Route path="/patient-details/:id" element={<PatientDetails />} />
      </Routes>
  );
}

export default App;
