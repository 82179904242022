import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const generateRandomData = (min, max) => {
  return Array.from({ length: 24 }, () =>
    Math.floor(Math.random() * (max - min + 1)) + min
  );
};

const ComparisonGraphs = () => {
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);

  useEffect(() => {
    const createChart = (chartRef, data, title) => {
      if (!chartRef?.current) return;

      new Chart(chartRef.current, {
        type: "line",
        data: {
          labels: Array.from({ length: 24 }, (_, i) => `${i}:00`),
          datasets: [
            {
              label: "Glucose Level (Median)",
              data: data.median,
              borderColor: "blue",
              borderWidth: 2,
              fill: false,
              tension: 0.4,
              pointRadius: 0,
            },
            {
              label: "Target Range (70-180)",
              data: Array(24).fill(70),
              backgroundColor: "rgba(0, 255, 0, 0.2)",
              borderWidth: 0,
              fill: "+1",
              pointRadius: 0,
            },
            {
              label: "Target Range (70-180)",
              data: Array(24).fill(180),
              backgroundColor: "rgba(0, 255, 0, 0.2)",
              borderWidth: 0,
              fill: "-1",
              pointRadius: 0,
            },
            {
              label: "High Range (181-250)",
              data: Array(24).fill(250),
              backgroundColor: "rgba(255, 165, 0, 0.2)",
              borderWidth: 0,
              fill: "+1",
              pointRadius: 0,
            },
            {
              label: "Low Range (Below 70)",
              data: Array(24).fill(54),
              backgroundColor: "rgba(255, 0, 0, 0.2)",
              borderWidth: 0,
              fill: "-1",
              pointRadius: 0,
            },
            {
              label: "5th Percentile",
              data: data.percentile5,
              borderColor: "rgba(255, 0, 0, 0.8)",
              borderWidth: 1,
              fill: "+1",
              tension: 0.4,
              pointRadius: 0,
            },
            {
              label: "95th Percentile",
              data: data.percentile95,
              borderColor: "rgba(255, 0, 0, 0.8)",
              borderWidth: 1,
              fill: "-1",
              tension: 0.4,
              pointRadius: 0,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: title,
              font: {
                size: 18,
              },
            },
            legend: {
              display: true,
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: "Time of Day",
              },
            },
            y: {
              title: {
                display: true,
                text: "Glucose Level (mg/dL)",
              },
              suggestedMin: 50,
              suggestedMax: 400,
            },
          },
        },
      });
    };

    const dataBestDay = {
      median: generateRandomData(100, 200),
      percentile5: generateRandomData(50, 100),
      percentile95: generateRandomData(200, 300),
    };

    const dataWorstDay = {
      median: generateRandomData(120, 220),
      percentile5: generateRandomData(60, 120),
      percentile95: generateRandomData(220, 320),
    };

    createChart(chartRef1, dataBestDay, "Best Day");
    createChart(chartRef2, dataWorstDay, "Worst Day");

    return () => {
      chartRef1.current && Chart.getChart(chartRef1.current)?.destroy();
      chartRef2.current && Chart.getChart(chartRef2.current)?.destroy();
    };
  }, []);

  return (
    <div style={{ display: "flex", justifyContent: "space-around", gap: "20px" }}>
      <div style={{ width: "45%" }}>
        <canvas ref={chartRef1}></canvas>
      </div>
      <div style={{ width: "45%" }}>
        <canvas ref={chartRef2}></canvas>
      </div>
    </div>
  );
};

export default ComparisonGraphs;
