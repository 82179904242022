import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/PatientList.css";

const PatientList = () => {
  const [patients, setPatients] = useState([]);
  const [appointments, setAppointments] = useState({});
  const navigate = useNavigate();

  // Генерация фейковых пациентов
  const generateFakePatients = () => [
    {
      id: 1,
      mrn: "123456",
      name: "John Doe",
      gender: "Male",
      dob: "1987-05-15",
    },
    {
      id: 2,
      mrn: "654321",
      name: "Jane Smith",
      gender: "Female",
      dob: "1992-10-25",
    },
  ];

  // Генерация фейковых приемов
  const generateFakeAppointments = (patients) => {
    const now = new Date();
    const fakeAppointments = {};
    patients.forEach((patient) => {
      const appointmentsForPatient = Array.from({ length: 3 }, (_, index) => {
        const appointmentDate = new Date(now);
        appointmentDate.setDate(appointmentDate.getDate() + index);
        return {
          id: `${patient.id}-${index + 1}`,
          date: appointmentDate.toISOString().split("T")[0],
          time: `${10 + index}:00`,
          reason: "Routine Checkup",
          practitioner: `Dr. ${index % 2 === 0 ? "Smith" : "Brown"}`,
        };
      });
      fakeAppointments[patient.id] = appointmentsForPatient;
    });
    return fakeAppointments;
  };

  const fetchPatientsAndAppointments = async () => {
    try {
      const response = await fetch("/api/athenahealth/patients");
      if (!response.ok) throw new Error("Failed to fetch patients");
      const patientData = await response.json();

      setPatients(patientData.patients || []);
      
      const appointmentsData = {};
      for (const patient of patientData.patients || []) {
        const appointmentResponse = await fetch(
          `/api/athenahealth/appointments/${patient.id}`
        );
        if (appointmentResponse.ok) {
          const appointmentList = await appointmentResponse.json();
          appointmentsData[patient.id] = appointmentList.appointments || [];
        }
      }
      setAppointments(appointmentsData);
    } catch (error) {
      console.error("Error fetching patients or appointments:", error);

      const fakePatients = generateFakePatients();
      setPatients(fakePatients);
      setAppointments(generateFakeAppointments(fakePatients));
    }
  };

  useEffect(() => {
    fetchPatientsAndAppointments();
  }, []);

  return (
    <div className="patient-list-wrapper">
      <h1 className="patient-list-title">Patient List</h1>
      <table className="patient-table">
        <thead>
          <tr>
            <th>MRN</th>
            <th>Name</th>
            <th>Gender</th>
            <th>DOB</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {patients.map((patient) => (
            <tr key={patient.id}>
              <td>{patient.mrn}</td>
              <td>{patient.name}</td>
              <td>{patient.gender}</td>
              <td>{patient.dob}</td>
              <td>
                <button
                  className="view-button"
                  onClick={() => navigate(`/patient-details/${patient.id}`)}
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="appointments-section">
        <h2>Appointments</h2>
        {patients.map((patient) => (
          <div key={patient.id} className="appointment-block">
            <h3>Appointments for {patient.name}</h3>
            <ul>
              {appointments[patient.id] ? (
                appointments[patient.id].map((appointment) => (
                  <li key={appointment.id}>
                    <strong>Date:</strong> {appointment.date}{" "}
                    <strong>Time:</strong> {appointment.time}{" "}
                    <strong>Reason:</strong> {appointment.reason}{" "}
                    <strong>Practitioner:</strong> {appointment.practitioner}
                  </li>
                ))
              ) : (
                <li>No Appointments</li>
              )}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PatientList;