const TimeInRangeChart = ({ data }) => {
  if (!data || Object.keys(data).length === 0) {
    return <div>No data available for this period.</div>;
  }

  const timeInRangeData = [
    { name: "Very High", value: data.percentAbove250 || 0, color: "#f4bb57", goal: "<5%", range: ">250 mg/dL" },
    { name: "High", value: data.percentAbove180 || 0, color: "#fff36b", goal: "<25%", range: "181-250 mg/dL" },
    { name: "Target", value: data.percentBetween70And180 || 0, color: "#a0ce6e", goal: ">70%", range: "70-180 mg/dL" },
    { name: "Low", value: data.percentBelow70 || 0, color: "#dd3b33", goal: "<4%", range: "54-69 mg/dL" },
    { name: "Very Low", value: data.percentBelow54 || 0, color: "#99292c", goal: "<1%", range: "<54 mg/dL" },
  ];

  const totalHeight = 300;

  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", marginTop: "20px" }}>
      <div
        style={{
          width: "60px",
          height: `${totalHeight}px`,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          border: "1px solid #ddd",
          borderRadius: "4px",
        }}
      >
        {timeInRangeData.map((item, index) => (
          <div
            key={index}
            style={{
              backgroundColor: item.color,
              height: `${(item.value / 100) * totalHeight}px`,
              width: "100%",
            }}
          ></div>
        ))}
      </div>

      <div style={{ marginLeft: "20px", width: "100%" }}>
        {timeInRangeData.map((item, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px dashed #ccc",
              padding: "10px 0",
            }}
          >
            <div>
              <div style={{ fontWeight: "bold", fontSize: "16px" }}>{item.name}</div>
              <div style={{ fontSize: "14px", color: "#555" }}>{item.range}</div>
            </div>
            <div style={{ textAlign: "right" }}>
              <div style={{ fontWeight: "bold", fontSize: "16px" }}>{item.value}%</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TimeInRangeChart;
