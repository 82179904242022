import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import FilterPanel from "./FilterPanel";
import TimeInRangeChart from "./TimeInRangeScale";
import GlucoseProfileGraph from "./GlucoseProfileGraph";
import InsulinBolusGraph from "./InsulinBolusGraph";
import DayComparisonGraphs from "./DayComparisonGraphs";
import DailyGlucoseProfiles from "./DailyGlucoseProfiles";
import ComparisonGraphs from "./ComparisonGraphs";
import patients from "../data/patients"; // Import fallback data
import "../css/PatientDetails.css";

const calculateAge = (dob) => {
  const today = new Date();
  const birthDate = new Date(dob);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const PatientDetails = () => {
  const { id } = useParams(); // Extract patient ID from URL
  const [filters, setFilters] = useState({
    period: "Last 14 Days",
    selectedFilter: "glucoseProfile",
    specificDay: "",
    fromDate: "",
    toDate: "",
  });
  const [patientData, setPatientData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [useFallback, setUseFallback] = useState(false); // Flag for fallback data

  useEffect(() => {
    const fetchPatientData = async () => {
      try {
        // Try fetching data from the database or API
        const response = await fetch(`/api/patients/${id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch patient data");
        }
        const data = await response.json();
        setPatientData(data); // Set data from API
      } catch (error) {
        console.error("Error fetching patient data:", error);

        // Use fallback data if API/database fails
        const fallbackPatient = patients[id];
        if (fallbackPatient) {
          setPatientData(fallbackPatient);
          setUseFallback(true); // Set fallback flag
        } else {
          console.error(`No fallback data found for patient ID: ${id}`);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchPatientData();
  }, [id]);

  const handleFilterChange = (updatedFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...updatedFilters,
    }));
  };

  const renderSelectedComponent = () => {
    if (!patientData?.statistics) {
      return <div>No data available.</div>;
    }

    let periodData = {};

    switch (filters.period) {
      case "Last 7 Days":
        periodData = patientData.statistics.periods?.last7Days || {};
        break;
      case "Last 14 Days":
        periodData = patientData.statistics.periods?.last14Days || {};
        break;
      case "Last 30 Days":
        periodData = patientData.statistics.periods?.last30Days || {};
        break;
      case "Specific Day":
        if (!filters.specificDay) {
          return <div>Please select a specific day to view data.</div>;
        }
        periodData =
          patientData.statistics.specificDay?.date === filters.specificDay
            ? patientData.statistics.specificDay
            : null;
        if (!periodData) {
          return <div>No data available for the selected day.</div>;
        }
        break;
      case "All Measures":
        periodData = patientData.statistics || {};
        break;
      case "From / To Days":
        if (!filters.fromDate || !filters.toDate) {
          return <div>Please select a date range to view data.</div>;
        }
        periodData = {
          ...patientData.statistics.dateRange,
          from: filters.fromDate,
          to: filters.toDate,
        };
        break;
      default:
        periodData = {};
    }

    switch (filters.selectedFilter) {
      case "glucoseProfile":
        return (
          <div className="glucose-profile-row">
            <div className="glucose-info">
              <p>Average Glucose:</p> <strong>{periodData?.averageGlucose || "N/A"}</strong>
              <p>Standard Deviation:</p>{" "}
              <strong>{periodData?.standardDeviation || "N/A"}</strong>
              <p>GMI:</p> <strong>{periodData?.GMI || "N/A"}</strong>
            </div>
            <div className="time-in-range">
              <h3>Time in Ranges</h3>
              <TimeInRangeChart data={periodData} />
            </div>
            <div className="glucose-info">
              <p>Sensor Type:</p> <strong>{periodData?.sensorType || "N/A"}</strong>
              <p>CGM Usage:</p> <strong>{periodData?.CGMUsage || "N/A"}</strong>
              <p>Time CGM is Active:</p> <strong>{periodData?.CGMActiveTime || "N/A"}</strong>
              <p>Latest Results:</p> <strong>{periodData?.latestResults || "N/A"}</strong>
              <p>Average Calibration per Day:</p> <strong>{periodData?.calibrationPerDay || "N/A"}</strong>
            </div>
          </div>
        );
      case "dailyGlucoseProfiles":
        return <DailyGlucoseProfiles data={patientData} />;
      case "ambulatoryProfile":
        return <GlucoseProfileGraph data={patientData} />;
      case "insulinBolusGraph":
        return <InsulinBolusGraph filters={filters} />;
      case "daysOfWeek":
        return <DayComparisonGraphs />;
      case "bestWorstDay":
        return <h3>Best/Worst Day Analysis</h3>;
      case "compare":
        return <ComparisonGraphs />;
      default:
        return null;
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!patientData) {
    return <div>Error loading patient details.</div>;
  }

  const { mrn, name, gender, dob } = patientData.patientData || {};
  const age = dob ? calculateAge(dob) : "N/A";

  return (
    <div className="patient-dashboard">
      <div className="patient-info">
        <span>
          <strong>MRN:</strong> {mrn}
        </span>
        <span>
          <strong>Patient Name:</strong> {name}
        </span>
        <span>
          <strong>Gender:</strong> {gender}
        </span>
        <span>
          <strong>DOB:</strong> {dob} ({age} yo)
        </span>
        {/*{useFallback && <p className="fallback-warning">Using fallback data.</p>}*/}
      </div>

      <FilterPanel onFilterChange={handleFilterChange} />

      <div className="right-content">
        <h2>
          Ambulatory glucose monitoring{" "}
          {(() => {
            switch (filters.period) {
              case "Last 7 Days":
              case "Last 14 Days":
              case "Last 30 Days":
                return filters.period.toLowerCase();
              case "Specific Day":
                return filters.specificDay ? `on ${filters.specificDay}` : "select a date";
              case "From / To Days":
                return `from ${filters.fromDate || "start"} to ${filters.toDate || "end"}`;
              case "All Measures":
                return "for all measures";
              default:
                return "last 14 days";
            }
          })()}
          .
        </h2>

        {renderSelectedComponent()}
      </div>
    </div>
  );
};

export default PatientDetails;
