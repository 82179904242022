import React from "react";
import { Chart } from "react-chartjs-2";

const DailyGlucoseProfiles = ({ data }) => {
  const dayLabels = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const glucoseData = data.entries; 

  const generateDailyChartData = (entry) => ({
    labels: Array.from({ length: entry.data.length }, (_, i) => `${i}:00`),
    datasets: [
      {
        label: "Glucose Levels",
        data: entry.data,
        borderColor: "rgba(0, 123, 255, 1)",
        backgroundColor: "rgba(255, 193, 7, 0.3)", 
        borderWidth: 1.5,
        fill: true,
        tension: 0.4,
        pointRadius: 0,
      },
      {
        label: "Target Range (70-180)",
        data: Array(entry.data.length).fill(180),
        borderColor: "rgba(0, 200, 0, 0.5)",
        backgroundColor: "rgba(0, 200, 0, 0.2)", 
        borderWidth: 0,
        fill: "+1",
        pointRadius: 0,
      },
      {
        label: "Target Range (70-180)",
        data: Array(entry.data.length).fill(70),
        borderColor: "rgba(0, 200, 0, 0.5)",
        backgroundColor: "rgba(0, 200, 0, 0.2)",
        borderWidth: 0,
        fill: "-1",
        pointRadius: 0,
      },
    ],
  });

  return (
    <div className="daily-glucose-profiles">
      <h3>Daily Glucose Profiles</h3>
      <div className="daily-charts-grid">
        {glucoseData.map((entry, index) => (
          <div key={index} className="daily-chart">
            <h4>{dayLabels[index % 7]}</h4>
            <Chart
              type="line"
              data={generateDailyChartData(entry)}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    display: false, 
                  },
                  y: {
                    suggestedMin: 50,
                    suggestedMax: 400,
                    ticks: {
                      stepSize: 50,
                    },
                  },
                },
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DailyGlucoseProfiles;
