import React, { useState } from "react";
import "../css/FilterPanel.css";

const FilterPanel = ({ onFilterChange }) => {
  const [selectedFilter, setSelectedFilter] = useState("glucoseProfile");
  const [selectedPeriod, setSelectedPeriod] = useState("Last 14 Days");
  const [specificDay, setSpecificDay] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
  
    const additionalData =
      period === "Specific Day"
        ? { specificDay: "" } 
        : period === "From / To Days"
        ? { fromDate: "", toDate: "" }
        : {};
  
    onFilterChange({ selectedFilter, period, ...additionalData });
  };

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    onFilterChange({ selectedFilter: filter, period: selectedPeriod });
  };

  const handleSpecificDayChange = (e) => {
    const day = e.target.value; 
    setSpecificDay(day);
    if (day) {
      onFilterChange({ selectedFilter, period: "Specific Day", specificDay: day });
    }
  };

  const handleFromDateChange = (e) => {
    const from = e.target.value;
    setFromDate(from);
    onFilterChange({ selectedFilter, period: "From / To Days", fromDate: from, toDate });
  };

  const handleToDateChange = (e) => {
    const to = e.target.value;
    setToDate(to);
    onFilterChange({ selectedFilter, period: "From / To Days", fromDate, toDate: to });
  };

  const handlePDFReport = () => {
    // Логика для создания PDF отчета
    const reportData = {
      selectedFilter,
      selectedPeriod,
      specificDay: selectedPeriod === "Specific Day" ? specificDay : undefined,
      fromDate: selectedPeriod === "From / To Days" ? fromDate : undefined,
      toDate: selectedPeriod === "From / To Days" ? toDate : undefined,
    };
    console.log("Generating PDF report with data:", reportData);
    alert("PDF Report generation started!");
  };

  return (
    <div className="filter-panel">
      <div className="filter-panel__dropdown">
        <label>Report Period:</label>
        <select value={selectedPeriod} onChange={(e) => handlePeriodChange(e.target.value)}>
          <option>Last 7 Days</option>
          <option>Last 14 Days</option>
          <option>Last 30 Days</option>
          <option>Specific Day</option>
          <option>All Measures</option>
          <option>From / To Days</option>
        </select>
      </div>

      {/* Specific Day Picker */}
      {selectedPeriod === "Specific Day" && (
        <div className="filter-panel__specific-day">
          <label>Select Specific Day:</label>
          <input type="date" value={specificDay} onChange={handleSpecificDayChange} />
        </div>
      )}

      {/* From / To Date Picker */}
      {selectedPeriod === "From / To Days" && (
        <div className="filter-panel__date-range">
          <label>From:</label>
          <input type="date" value={fromDate} onChange={handleFromDateChange} />
          <label>To:</label>
          <input type="date" value={toDate} onChange={handleToDateChange} />
        </div>
      )}

      <div className="filter-panel__radio-buttons">
        <label>
          <input
            type="radio"
            name="filter"
            value="glucoseProfile"
            checked={selectedFilter === "glucoseProfile"}
            onChange={() => handleFilterChange("glucoseProfile")}
          />
          Glucose Profile
        </label>
        <label>
          <input
            type="radio"
            name="filter"
            value="ambulatoryProfile"
            checked={selectedFilter === "ambulatoryProfile"}
            onChange={() => handleFilterChange("ambulatoryProfile")}
          />
          Ambulatory Glucose Profile
        </label>
        <label>
          <input
            type="radio"
            name="filter"
            value="dailyGlucoseProfiles"
            checked={selectedFilter === "dailyGlucoseProfiles"}
            onChange={() => handleFilterChange("dailyGlucoseProfiles")}
          />
          Daily Glucose Profiles
        </label>
        <label>
          <input
            type="radio"
            name="filter"
            value="insulinBolusGraph"
            checked={selectedFilter === "insulinBolusGraph"}
            onChange={() => handleFilterChange("insulinBolusGraph")}
          />
          Show Insulin Bolus Graph
        </label>
        <label>
          <input
            type="radio"
            name="filter"
            value="daysOfWeek"
            checked={selectedFilter === "daysOfWeek"}
            onChange={() => handleFilterChange("daysOfWeek")}
          />
          Days of the Week
        </label>
        <label>
          <input
            type="radio"
            name="filter"
            value="bestWorstDay"
            checked={selectedFilter === "bestWorstDay"}
            onChange={() => handleFilterChange("bestWorstDay")}
          />
          Best / Worst Day
        </label>
        <label>
          <input
            type="radio"
            name="filter"
            value="compare"
            checked={selectedFilter === "compare"}
            onChange={() => handleFilterChange("compare")}
          />
          Compare
        </label>
      </div>

      <button className="filter-panel__button" onClick={handlePDFReport}>
        PDF Report
      </button>
    </div>
  );
};

export default FilterPanel;
