import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const DayComparisonGraphs = ({ data1, data2 }) => {
  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);

  useEffect(() => {
    const createChart = (chartRef, data, title) => {
      if (!data || !chartRef?.current) return;

      new Chart(chartRef.current, {
        type: "line",
        data: {
          labels: Array.from({ length: 24 }, (_, i) => `${i}:00`), 
          datasets: [
            {
              label: "Target Range (70-180)",
              data: Array(24).fill(70),
              backgroundColor: "rgba(163, 205, 108, 0.5)",
              borderWidth: 0,
              fill: "+1",
              pointRadius: 0,
            },
            {
              label: "Target Range (70-180)",
              data: Array(24).fill(180),
              backgroundColor: "rgba(0, 255, 0, 0.1)",
              borderWidth: 0,
              fill: "-1",
              pointRadius: 0,
            },
            {
              label: "Median (50%)",
              data: data.percentages.percentile_50,
              borderColor: "rgba(0, 123, 255, 1)",
              borderWidth: 1.5,
              pointRadius: 0,
              fill: false,
              tension: 0.4,
            },
            {
              label: "5th Percentile",
              data: data.percentages.percentile_5,
              borderColor: "rgba(255, 0, 0, 0.5)",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
              borderWidth: 1,
              pointRadius: 0,
              fill: "+1",
              tension: 0.4,
            },
            {
              label: "95th Percentile",
              data: data.percentages.percentile_95,
              borderColor: "rgba(255, 0, 0, 0.5)",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
              borderWidth: 1,
              pointRadius: 0,
              fill: "-1",
              tension: 0.4,
            },
            {
              label: "25th Percentile",
              data: data.percentages.percentile_25,
              borderColor: "rgba(75, 192, 192, 0.5)",
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderWidth: 1,
              pointRadius: 0,
              fill: "+1",
              tension: 0.4,
            },
            {
              label: "75th Percentile",
              data: data.percentages.percentile_75,
              borderColor: "rgba(75, 192, 192, 0.5)",
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderWidth: 1,
              pointRadius: 0,
              fill: "-1",
              tension: 0.4,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: title,
              font: {
                size: 18,
              },
            },
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: "Time of Day",
              },
              grid: {
                drawOnChartArea: false,
              },
            },
            y: {
              title: {
                display: true,
                text: "Glucose Level (mg/dL)",
              },
              ticks: {
                stepSize: 50,
              },
              suggestedMin: 50,
              suggestedMax: 400,
            },
          },
        },
      });
    };

    createChart(chartRef1, data1, "Monday");
    createChart(chartRef2, data2, "Tuesday");
  }, [data1, data2]);

  return (
      <div style={{ display: "flex", justifyContent: "space-around", gap: "20px" }}>
        <div style={{ width: "45%" }}>
          <canvas ref={chartRef1}></canvas>
        </div>
        <div style={{ width: "45%" }}>
          <canvas ref={chartRef2}></canvas>
        </div>
      </div>
  );
};

export default DayComparisonGraphs;
