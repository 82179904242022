export const _patient2 = {
  startAt: "2024-11-01T00:00:00",
  endAt: "2024-11-30T23:59:59",
  patientData: {
    mrn: "987456",
    name: "Jane Smith",
    gender: "Female",
   dob: "1992-10-25",
  },
  statistics: {
    count: 0,
    percentBelow54: 5,
    percentBelow70: 6,
    percentBelowTarget: 11,
    percentBetween70And180: 37,
    percentAboveTarget: 53,
    percentAbove180: 21,
    percentAbove250: 32,
    average: 200,
    standardDeviation: 102.55,
    coefficientOfVariation: 0,
    coefficientOfVariationPercentage: 51.19,
    a1c: 8.6,
    percentiles: {
      fifth: 53,
      twentyFifth: 117,
      fiftieth: 187.5,
      seventyFifth: 277,
      ninetyFifth: 373,
    },
    periods: {
      last7Days: {
        averageGlucose: 180,
        standardDeviation: 90,
        GMI: 7.8,
        sensorType: "Dexcom",
        CGMUsage: "90%",
        CGMActiveTime: "95%",
        latestResults: "110 mg/dL",
        calibrationPerDay: "87%",
        percentAbove250: 10,
        percentAbove180: 20,
        percentBetween70And180: 60,
        percentBelow70: 7,
        percentBelow54: 3,
      },
      last14Days: {
        averageGlucose: 175,
        standardDeviation: 85,
        GMI: 7.6,
        sensorType: "Dexcom",
        CGMUsage: "88%",
        CGMActiveTime: "94%",
        latestResults: "108 mg/dL",
        calibrationPerDay: "85%",
        percentAbove250: 12,
        percentAbove180: 25,
        percentBetween70And180: 58,
        percentBelow70: 8,
        percentBelow54: 4,
      },
      last30Days: {
        averageGlucose: 170,
        standardDeviation: 80,
        GMI: 7.5,
        sensorType: "Dexcom",
        CGMUsage: "86%",
        CGMActiveTime: "93%",
        latestResults: "105 mg/dL",
        calibrationPerDay: "83%",
        percentAbove250: 15,
        percentAbove180: 30,
        percentBetween70And180: 50,
        percentBelow70: 10,
        percentBelow54: 5,
      },
    },
    specificDay: {
      date: "2024-11-15",
      averageGlucose: 145,
      standardDeviation: 70,
      GMI: 7.2,
      sensorType: "Dexcom",
      CGMUsage: "89%",
      CGMActiveTime: "94%",
      latestResults: "112 mg/dL",
      calibrationPerDay: "86%",
      percentAbove250: 8,
      percentAbove180: 18,
      percentBetween70And180: 65,
      percentBelow70: 6,
      percentBelow54: 3,
    },
    dateRange: {
      from: "2024-11-01",
      to: "2024-11-15",
      averageGlucose: 165,
      standardDeviation: 75,
      GMI: 7.4,
      sensorType: "Dexcom",
      CGMUsage: "87%",
      CGMActiveTime: "93%",
      latestResults: "107 mg/dL",
      calibrationPerDay: "84%",
      percentAbove250: 14,
      percentAbove180: 28,
      percentBetween70And180: 55,
      percentBelow70: 8,
      percentBelow54: 4,
    },
    gmi: 8.1,
  },
  percentages: {
    percentile_5: [
      49.51666666666667, 44.60833333333333, 40.916666666666664, 41, 41.5,
      40.333333333333336, 40, 40.425000000000004, 44.4, 54.07500000000001,
      57.76666666666667, 60.68333333333334, 58.666666666666664,
      64.68333333333334, 107.06666666666666, 130.95833333333334,
      127.19166666666666, 113.39999999999999, 106.8, 113.31666666666666,
      90.95833333333333, 66.88333333333334, 58.68333333333334,
      54.06666666666666, 49.51666666666667,
    ],
    percentile_25: [
      158.54166666666666, 142.29166666666666, 124, 105.58333333333333,
      91.33333333333333, 83.16666666666667, 69.79166666666667,
      59.041666666666664, 67.41666666666667, 97.04166666666667,
      112.95833333333333, 125.16666666666667, 147.66666666666666,
      167.79166666666666, 195, 225.125, 231.16666666666666, 224.04166666666666,
      222.875, 225.91666666666666, 230, 212.91666666666666, 192.16666666666666,
      176.25, 158.54166666666666,
    ],
    percentile_50: [
      192.66666666666666, 169.16666666666666, 150.08333333333334,
      131.58333333333334, 114, 103.83333333333333, 89.5, 85.08333333333333,
      99.25, 118, 135.5, 156.41666666666666, 187.5, 230.5, 278.3333333333333,
      304.3333333333333, 310.6666666666667, 305.9166666666667,
      296.8333333333333, 287.75, 281.5833333333333, 268.5, 249.75, 222.75,
      192.66666666666666,
    ],
    percentile_75: [
      247.16666666666666, 225.25, 198.58333333333334, 178.91666666666666,
      165.75, 156.625, 144.66666666666666, 133.20833333333334, 139.25,
      168.54166666666666, 176, 189.20833333333334, 238, 289.4166666666667,
      339.8333333333333, 350, 350, 350, 350, 342.0833333333333,
      331.2083333333333, 312.25, 287.9166666666667, 263.8333333333333,
      247.16666666666666,
    ],
    percentile_95: [
      350, 308.375, 268.5833333333333, 248.91666666666666, 232.875,
      222.6916666666667, 208.59166666666667, 198.79999999999998,
      211.29166666666663, 273.6083333333333, 292.74999999999994,
      292.84999999999997, 298.25, 312.68333333333334, 350, 350, 350, 350, 350,
      350, 350, 350, 350, 350, 350,
    ],
    shadingR_5_95: [
      {
        below50: 143.14999999999998,
        above50: 157.33333333333334,
      },
      {
        below50: 124.55833333333334,
        above50: 139.20833333333334,
      },
      {
        below50: 109.16666666666669,
        above50: 118.49999999999997,
      },
      {
        below50: 90.58333333333334,
        above50: 117.33333333333331,
      },
      {
        below50: 72.5,
        above50: 118.875,
      },
      {
        below50: 63.49999999999999,
        above50: 118.85833333333336,
      },
      {
        below50: 49.5,
        above50: 119.09166666666667,
      },
      {
        below50: 44.658333333333324,
        above50: 113.71666666666665,
      },
      {
        below50: 54.85,
        above50: 112.04166666666663,
      },
      {
        below50: 63.92499999999999,
        above50: 155.6083333333333,
      },
      {
        below50: 77.73333333333332,
        above50: 157.24999999999994,
      },
      {
        below50: 95.73333333333332,
        above50: 136.4333333333333,
      },
      {
        below50: 128.83333333333334,
        above50: 110.75,
      },
      {
        below50: 165.81666666666666,
        above50: 82.18333333333334,
      },
      {
        below50: 171.26666666666665,
        above50: 71.66666666666669,
      },
      {
        below50: 173.37499999999997,
        above50: 45.666666666666686,
      },
      {
        below50: 183.47500000000002,
        above50: 39.333333333333314,
      },
      {
        below50: 192.5166666666667,
        above50: 44.083333333333314,
      },
      {
        below50: 190.0333333333333,
        above50: 53.166666666666686,
      },
      {
        below50: 174.43333333333334,
        above50: 62.25,
      },
      {
        below50: 190.625,
        above50: 68.41666666666669,
      },
      {
        below50: 201.61666666666667,
        above50: 81.5,
      },
      {
        below50: 191.06666666666666,
        above50: 100.25,
      },
      {
        below50: 168.68333333333334,
        above50: 127.25,
      },
      {
        below50: 143.14999999999998,
        above50: 157.33333333333334,
      },
    ],
    shadingR_25_75: [
      {
        below50: 34.125,
        above50: 54.5,
      },
      {
        below50: 26.875,
        above50: 56.08333333333334,
      },
      {
        below50: 26.083333333333343,
        above50: 48.5,
      },
      {
        below50: 26.000000000000014,
        above50: 47.333333333333314,
      },
      {
        below50: 22.66666666666667,
        above50: 51.75,
      },
      {
        below50: 20.666666666666657,
        above50: 52.79166666666667,
      },
      {
        below50: 19.70833333333333,
        above50: 55.16666666666666,
      },
      {
        below50: 26.041666666666664,
        above50: 48.125000000000014,
      },
      {
        below50: 31.83333333333333,
        above50: 40,
      },
      {
        below50: 20.95833333333333,
        above50: 50.54166666666666,
      },
      {
        below50: 22.54166666666667,
        above50: 40.5,
      },
      {
        below50: 31.249999999999986,
        above50: 32.791666666666686,
      },
      {
        below50: 39.83333333333334,
        above50: 50.5,
      },
      {
        below50: 62.70833333333334,
        above50: 58.916666666666686,
      },
      {
        below50: 83.33333333333331,
        above50: 61.5,
      },
      {
        below50: 79.20833333333331,
        above50: 45.666666666666686,
      },
      {
        below50: 79.50000000000003,
        above50: 39.333333333333314,
      },
      {
        below50: 81.87500000000003,
        above50: 44.083333333333314,
      },
      {
        below50: 73.95833333333331,
        above50: 53.166666666666686,
      },
      {
        below50: 61.83333333333334,
        above50: 54.333333333333314,
      },
      {
        below50: 51.583333333333314,
        above50: 49.625,
      },
      {
        below50: 55.58333333333334,
        above50: 43.75,
      },
      {
        below50: 57.58333333333334,
        above50: 38.166666666666686,
      },
      {
        below50: 46.5,
        above50: 41.083333333333314,
      },
      {
        below50: 34.125,
        above50: 54.5,
      },
    ],
  },
  entries: [
    {
      date: "03/12/2023",
      day: 12,
      data: [
        243, 237, 228, 223, 216, 202, 184, 175, 169, 160, 152, 147, 145, 144,
        135, 122, 119, 114, 106, 108, 118, 120, 114, 109, 108, 108, 105, 102,
        106, 108, 106, 104, 100, 104, 109, 110, 108, 102, 105, 123, 147, 165,
        167, 159, 155, 166, 178, 183, 209, 250, 274, 293, 302, 292, 309, 340,
        369, 396, 421, 439, 448, 451, 440, 436, 461, 489, 497, 487, 474, 470,
        472, 468, 466, 471, 480, 491, 498, 497, 496, 491, 480, 477, 481, 478,
        465, 447, 427, 407, 398, 401, 403, 394, 387, 383, 376, 367,
      ],
      maxValue: 498,
      minValue: 100,
    },
    {
      date: "03/13/2023",
      day: 13,
      data: [
        366, 356, 342, 328, 315, 304, 299, 295, 278, 259, 255, 261, 257, 248,
        244, 239, 233, 230, 229, 224, 226, 230, 218, 210, 206, 205, 204, 202,
        198, 197, 192, 190, 189, 182, 180, 180, 177, 176, 183, 189, 184, 172,
        164, 166, 163, 157, 148, 154, 185, 213, 225, 236, 244, 261, 283, 299,
        310, 310, 297, 284, 277, 272, 262, 252, 245, 232, 220, 206, 197, 189,
        179, 172, 166, 155, 142, 131, 127, 116, 96, 83, 78, 70, 62, 57, 58, 64,
        61, 56, 55, 56, 54, 52, 54, 52, 50, 49,
      ],
      maxValue: 366,
      minValue: 49,
    },
    {
      date: "03/14/2023",
      day: 14,
      data: [
        46, 43, 42, 43, 45, 43, 40, 40, 40, 40, 40, 40, 41, 41, 40, 41, 42, 42,
        42, 41, 41, 42, 44, 47, 51, 53, 53, 52, 52, 54, 58, 68, 98, 148, 177,
        178, 175, 176, 175, 174, 175, 178, 184, 191, 188, 180, 180, 182, 178,
        176, 175, 188, 232, 277, 310, 337, 353, 364, 363, 358, 359, 354, 334,
        317, 313, 312, 310, 306, 303, 303, 299, 297, 290, 285, 285, 282, 281,
        288, 311, 323, 319, 314, 312, 306, 302, 308, 302, 292, 287, 282, 276,
        272, 275, 279, 276, 272,
      ],
      maxValue: 364,
      minValue: 40,
    },
    {
      date: "03/15/2023",
      day: 15,
      data: [
        268, 267, 265, 257, 247, 241, 241, 242, 241, 233, 227, 230, 230, 227,
        228, 227, 223, 222, 216, 208, 206, 204, 202, 200, 198, 196, 192, 194,
        195, 188, 184, 183, 181, 178, 174, 173, 173, 174, 173, 170, 168, 164,
        160, 162, 169, 180, 195, 218, 236, 234, 224, 220, 215, 210, 211, 214,
        238, 268, 286, 303, 330, 348, 352, 355, 349, 342, 345, 347, 353, 358,
        358, 361, 368, 369, 368, 367, 363, 356, 351, 349, 343, 340, 341, 340,
        338, 334, 327, 318, 316, 313, 301, 292, 289, 282, 271, 261,
      ],
      maxValue: 369,
      minValue: 160,
    },
    {
      date: "03/16/2023",
      day: 16,
      data: [
        254, 249, 239, 230, 224, 216, 207, 202, 195, 190, 186, 181, 177, 174,
        171, 167, 164, 162, 161, 157, 153, 150, 149, 145, 138, 134, 132, 127,
        129, 148, 178, 200, 222, 251, 278, 300, 316, 324, 318, 304, 295, 294,
        293, 289, 296, 303, 298, 299, 304, 304, 294, 287, 287, 282, 278, 281,
        279, 276, 283, 290, 295, 295, 290, 288, 287, 303, 322, 326, 321, 316,
        307, 301, 297, 294, 300, 309, 310, 301, 288, 283, 284, 275, 267, 269,
        263, 254, 249, 245, 239, 228, 218, 206, 196, 190, 185, 179,
      ],
      maxValue: 326,
      minValue: 127,
    },
    {
      date: "03/17/2023",
      day: 17,
      data: [
        177, 170, 162, 159, 156, 147, 142, 138, 133, 128, 123, 122, 117, 109,
        103, 99, 97, 96, 93, 91, 90, 88, 86, 85, 84, 82, 81, 81, 80, 82, 82, 83,
        86, 93, 107, 119, 130, 138, 139, 142, 146, 148, 150, 152, 151, 149, 150,
        153, 154, 155, 157, 169, 214, 273, 319, 352, 378, 390, 405, 421, 425,
        424, 422, 414, 404, 396, 391, 384, 373, 370, 364, 353, 344, 336, 327,
        318, 308, 297, 287, 279, 276, 275, 270, 262, 252, 244, 239, 234, 229,
        223, 217, 214, 211, 199, 187, 181,
      ],
      maxValue: 425,
      minValue: 80,
    },
    {
      date: "03/18/2023",
      day: 18,
      data: [
        174, 166, 160, 156, 152, 147, 140, 134, 124, 115, 108, 105, 105, 102,
        95, 85, 75, 69, 67, 68, 65, 60, 59, 61, 60, 57, 55, 53, 50, 48, 46, 43,
        40, 40, 43, 51, 75, 101, 111, 114, 121, 131, 139, 137, 129, 124, 118,
        112, 107, 103, 103, 106, 110, 114, 115, 111, 112, 126, 144, 161, 178,
        192, 208, 223, 234, 247, 259, 266, 272, 275, 277, 292, 318, 335, 342,
        341, 337, 331, 333, 334, 330, 323, 316, 310, 297, 281, 264, 254, 248,
        240, 233, 229, 215, 200, 193, 189,
      ],
      maxValue: 342,
      minValue: 40,
    },
    {
      date: "03/19/2023",
      day: 19,
      data: [
        179, 164, 154, 150, 141, 131, 124, 118, 113, 109, 103, 95, 84, 74, 68,
        63, 58, 49, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 40, 42, 52, 54,
        51, 54, 83, 103, 111, 108, 109, 116, 115, 101, 86, 78, 75, 70, 65, 62,
        59, 56, 53, 53, 62, 98, 146, 175, 195, 216, 236, 245, 247, 244, 237,
        232, 229, 224, 222, 222, 222, 216, 206, 198, 196, 197, 203, 228, 263,
        283, 295, 302, 301, 300, 300, 294, 280, 273, 269, 266, 263, 255, 246,
        238, 231, 229, 229,
      ],
      maxValue: 302,
      minValue: 40,
    },
    {
      date: "03/20/2023",
      day: 20,
      data: [
        225, 219, 213, 200, 185, 175, 170, 168, 167, 158, 151, 148, 146, 141,
        134, 129, 119, 111, 106, 101, 96, 92, 88, 83, 78, 75, 74, 74, 70, 67,
        66, 65, 61, 58, 58, 74, 99, 109, 112, 117, 120, 123, 119, 112, 112, 123,
        163, 211, 238, 247, 250, 264, 295, 326, 346, 356, 357, 356, 369, 377,
        368, 359, 349, 337, 329, 320, 309, 298, 292, 287, 277, 270, 265, 271,
        277, 275, 266, 257, 247, 237, 232, 227, 220, 202, 175, 159, 173, 194,
        190, 184, 188, 189, 180, 169, 163, 160,
      ],
      maxValue: 377,
      minValue: 58,
    },
    {
      date: "03/21/2023",
      day: 21,
      data: [
        157, 153, 149, 145, 144, 143, 140, 136, 131, 126, 118, 114, 112, 107,
        107, 109, 107, 107, 103, 97, 91, 86, 80, 74, 68, 61, 59, 58, 56, 55, 53,
        51, 62, 110, 156, 196, 226, 235, 238, 243, 251, 259, 270, 279, 280, 285,
        291, 295, 299, 300, 300, 297, 292, 289, 284, 283, 286, 294, 312, 330,
        336, 337, 337, 344, 362, 373, 374, 370, 370, 375, 377, 375, 376, 378,
        377, 377, 381, 386, 388, 376, 365, 360, 356, 346, 335, 333, 329, 314,
        301, 286, 269, 259, 256, 251, 247, 241,
      ],
      maxValue: 388,
      minValue: 51,
    },
    {
      date: "03/22/2023",
      day: 22,
      data: [
        234, 227, 216, 204, 200, 198, 192, 186, 180, 172, 165, 166, 167, 165,
        162, 159, 158, 155, 148, 142, 139, 138, 138, 139, 138, 133, 133, 132,
        129, 128, 127, 126, 126, 125, 124, 124, 121, 118, 118, 117, 114, 117,
        123, 135, 154, 169, 186, 198, 194, 187, 183, 182, 190, 204, 210, 201,
        188, 181, 174, 167, 162, 159, 152, 156, 178, 193, 192, 182, 179, 198,
        224, 234, 228, 219, 215, 222, 225, 220, 210, 199, 188, 170, 168, 180,
        187, 187, 183, 168, 140, 117, 120, 135, 155, 159, 152, 148,
      ],
      maxValue: 234,
      minValue: 114,
    },
    {
      date: "03/23/2023",
      day: 23,
      data: [
        145, 143, 143, 143, 141, 138, 134, 129, 125, 121, 117, 115, 114, 112,
        111, 103, 96, 91, 87, 82, 82, 86, 87, 85, 85, 83, 80, 80, 79, 77, 75,
        73, 71, 68, 65, 63, 61, 59, 58, 56, 56, 55, 55, 62, 62, 59, 59, 59, 58,
        56, 54, 54, 63, 92, 114, 123, 127, 128, 131, 136, 136, 133, 130, 127,
        124, 118, 113, 112, 109, 105, 102, 101, 100, 95, 92, 108, 140, 175, 213,
        241, 246, 254, 269, 268, 267, 267, 261, 258, 257, 257, 255, 255, 256,
        255, 252, 250,
      ],
      maxValue: 269,
      minValue: 54,
    },
    {
      date: "03/24/2023",
      day: 24,
      data: [
        248, 244, 238, 236, 234, 226, 221, 218, 215, 211, 204, 198, 195, 194,
        188, 181, 179, 176, 173, 169, 162, 159, 154, 149, 145, 140, 134, 130,
        127, 125, 123, 117, 116, 119, 116, 112, 108, 104, 104, 103, 102, 101,
        101, 110, 137, 184, 235, 269, 269, 266, 266, 270, 296, 328, 347, 354,
        360, 363, 357, 352, 347, 339, 330, 321, 314, 307, 307, 314, 318, 322,
        322, 312, 303, 298, 289, 278, 268, 257, 248, 239, 232, 228, 222, 213,
        211, 212, 203, 191, 181, 171, 160, 150, 136, 121, 113, 108,
      ],
      maxValue: 363,
      minValue: 101,
    },
    {
      date: "03/25/2023",
      day: 25,
      data: [
        102, 95, 89, 86, 81, 72, 65, 63, 62, 58, 56, 54, 51, 49, 45, 51, 82,
        112, 124, 129, 143, 170, 201, 215,
      ],
      maxValue: 215,
      minValue: 45,
    },
  ],
};
