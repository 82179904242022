import React, { useEffect, useRef } from "react";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const InsulinBolusGraph = ({ filters }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const getDataForPeriod = (period) => {
    if (period === "Last 7 Days") {
      return {
        insulinData: [4, 8, 12, 16, 10, 14, 6],
        averageLevels: [5, 9, 13, 15, 11, 13, 7],
      };
    } else if (period === "Last 14 Days") {
      return {
        insulinData: [3, 7, 11, 15, 9, 13, 5],
        averageLevels: [4, 8, 12, 14, 10, 12, 6],
      };
    } else if (period === "Last 30 Days") {
      return {
        insulinData: [5, 10, 14, 18, 12, 16, 8],
        averageLevels: [6, 11, 15, 17, 13, 15, 9],
      };
    }
    return null;
  };

  useEffect(() => {
    if (!filters || !filters.period) {
      console.error("No filters or period provided");
      return;
    }

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const ctx = chartRef.current?.getContext("2d");
    const data = getDataForPeriod(filters.period);

    if (!data || !ctx) {
      console.error("No data or chart context available");
      return;
    }

    const { insulinData, averageLevels } = data;

    chartInstance.current = new Chart(ctx, {
      type: "bar",
      data: {
        labels: ["12AM", "4AM", "8AM", "12PM", "4PM", "8PM", "12AM"],
        datasets: [
          {
            label: "Insulin Boluses",
            data: insulinData,
            backgroundColor: "rgba(173, 216, 230, 0.6)", // Light blue
            borderColor: "rgba(0, 0, 0, 0.8)", // Black
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
          },
          title: {
            display: true,
            text: "Insulin Bolus Graph",
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Time",
            },
          },
          y: {
            title: {
              display: true,
              text: "Number of Insulin Boluses",
            },
            beginAtZero: true,
            max: 20,
            ticks: {
              stepSize: 5,
            },
          },
        },
      },
      plugins: [
        {
          id: "redLinePlugin",
          afterDatasetsDraw(chart) {
            const {
              ctx,
              chartArea: { top, bottom },
              scales: { x, y },
            } = chart;

            ctx.save();
            ctx.strokeStyle = "red"; // Red line for average
            ctx.lineWidth = 2;

            averageLevels.forEach((avg, index) => {
              const xPos = x.getPixelForValue(index);
              const yPos = y.getPixelForValue(avg);

              ctx.beginPath();
              ctx.moveTo(xPos - x.width / insulinData.length / 2.5, yPos);
              ctx.lineTo(xPos + x.width / insulinData.length / 2.5, yPos);
              ctx.stroke();
            });

            ctx.restore();
          },
        },
      ],
    });

    return () => {
      chartInstance.current?.destroy();
    };
  }, [filters]);

  return (
    <div>
      <h3>Insulin Bolus Graph</h3>
      <canvas ref={chartRef} />
    </div>
  );
};

export default InsulinBolusGraph;
